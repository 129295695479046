import SMSAPI from "../SMSAPI";
import EmailAPI from "../EmailAPI";
import AtendimentoAPI from "../AtendimentoAPI";
import codigodotokenConf from './conf/codigodotoken.json';
import protocoloadesaoConf from './conf/protocoloadesao.json';
import formapagamentoboletoConf from './conf/formapagamentoboleto.json';
import formapagamentofolhaConf from './conf/formapagamentofolha.json';
import informacoesperfilinvestimentoConf from './conf/informacoesperfilinvestimento.json';
import adesaopendenteanaliseConf from './conf/adesaopendenteanalise.json';
import protocoloadesaoAberturaConf from './conf/protocoloadesaoAbertura.json';
import protocolocriacaoadesaoAberturaConf from './conf/protocolocriacaoadesaoAbertura.json';


import moment from 'moment';
import Profile from '../profile/Profile'
import Common from "../common/Common";
class NotificationService {
   

    async envioDoToken(fluxo, ddi, ddd, numero, pessoa, token) {
        var codigodotokenPayload = Common.keepCloning(codigodotokenConf);

        SMSAPI.postMensagens(ddi, ddd, numero, codigodotokenPayload.sms.mensagem.replaceAll("[token]", token.toString()))
            .then(response => {
                console.log(response.data);
            })
            .catch(() => {
                //console.log(e);
            });


        var nome = "";
        var email = pessoa.email;
        switch (fluxo) {
            case "proprio":
            case "propriodpub":
                nome = pessoa.nome.split(" ")[0].initCap();
                break;
            case "maior18":
            case "convidadomaior18":
            case "aberturamaior18":
                nome = pessoa.nome.split(" ")[0].initCap();
                break;
            case "menor18":
            case "convidadomenor18":
            case "aberturamenor18":
                nome = pessoa.nome.split(" ")[0].initCap();
                email = pessoa.representante.email;
                break;
        }

        codigodotokenPayload.email.corpo = codigodotokenPayload.email.corpo.replaceAll("[nome]", nome);
        codigodotokenPayload.email.corpo = codigodotokenPayload.email.corpo.replaceAll("[token]", token.toString());

        EmailAPI.postMensagens(codigodotokenPayload.email, email)
            .then(response => {
                console.log(response.data);
            })
            .catch(() => {
                //console.log(e);
            });

        return "ok";
    }

    async protocoloadesao(fluxo, pessoa, parentesco, entrypoint) {
        var crmToken = await Common.criaTokenCRM();
        var protocoloadesaoPayload = Common.keepCloning(protocoloadesaoConf);

        var email = pessoa.email;
        var nome = "";
        var cpf = "";

        switch (fluxo) {
            case "proprio":
            case "propriodpub":
                nome = pessoa.nome.split(" ")[0].initCap();
                cpf = pessoa.cpf;
                break;
            case "maior18":
            case "convidadomaior18":
            case "aberturamaior18":
                nome = pessoa.nome.split(" ")[0].initCap();
                cpf = pessoa.cpf;
                break;
            case "menor18":
            case "convidadomenor18":
            case "aberturamenor18":
                nome = pessoa.nome.split(" ")[0].initCap();  //nome = pessoa["participante-valia"].nome.split(" ")[0].initCap();
                email = pessoa.representante.email;
                cpf = pessoa.representante.cpf;
                break;
        }

        console.log(cpf); 

        var contatoRequest = {
            nome: pessoa.nome,
            email: pessoa.email,
            cpf: pessoa.cpf
        }

        var contato = await AtendimentoAPI.postPessoa(crmToken, contatoRequest);
        var idContato = contato.data.id;


        protocoloadesaoPayload.email.corpo = protocoloadesaoPayload.email.corpo.replaceAll("[nome]", nome);
        protocoloadesaoPayload.email.corpo = protocoloadesaoPayload.email.corpo.replaceAll("[protocolo]", pessoa["detalhesDaAdesao"].protocolo);

        EmailAPI.postMensagens(protocoloadesaoPayload.email, email)
            .then(response => {
                console.log(response.data);
            })
            .catch(() => {
                //console.log(e);
            });


        protocoloadesaoPayload.crm.id_contato = idContato;
        protocoloadesaoPayload.crm.matricula = protocoloadesaoPayload.crm.matricula.replaceAll("[matricula]", pessoa["participante-valia"].matricula);

        protocoloadesaoPayload.crm.tipo = protocoloadesaoPayload.crm.tipo.replaceAll("[protocolo]", pessoa["detalhesDaAdesao"].protocolo);
        protocoloadesaoPayload.crm.tipo = protocoloadesaoPayload.crm.tipo.replaceAll("[matricula]", pessoa["participante-valia"].matricula);
        protocoloadesaoPayload.crm.tipo = protocoloadesaoPayload.crm.tipo.replaceAll("[cpf]", pessoa["participante-valia"].cpf);
        protocoloadesaoPayload.crm.tipo = protocoloadesaoPayload.crm.tipo.replaceAll("[parentesco]", parentesco);
        protocoloadesaoPayload.crm.tipo = protocoloadesaoPayload.crm.tipo.replaceAll("[entrypoint]", entrypoint);

        protocoloadesaoPayload.crm.encadeamento = protocoloadesaoPayload.crm.encadeamento.replaceAll("[protocolo]", pessoa["detalhesDaAdesao"].protocolo);
        protocoloadesaoPayload.crm.encadeamento = protocoloadesaoPayload.crm.encadeamento.replaceAll("[matricula]", pessoa["participante-valia"].matricula);
        protocoloadesaoPayload.crm.encadeamento = protocoloadesaoPayload.crm.encadeamento.replaceAll("[cpf]", pessoa["participante-valia"].cpf);
        protocoloadesaoPayload.crm.encadeamento = protocoloadesaoPayload.crm.encadeamento.replaceAll("[parentesco]", parentesco);
        protocoloadesaoPayload.crm.encadeamento = protocoloadesaoPayload.crm.encadeamento.replaceAll("[entrypoint]", entrypoint);

        AtendimentoAPI.postAtendimentoPadrao(protocoloadesaoPayload.crm, crmToken)
            .then(response => {
                console.log("CRM");
                console.log(response.data);
                console.log("/CRM");
            })
            .catch(() => {
                //console.log(e);
            });


        return "ok";
    }
    
    async formapagamentoboleto(fluxo, pessoa, ip, emailParticipanteValia) {
        
        var crmToken = await Common.criaTokenCRM();
        var formapagamentoboletoPayload = Common.keepCloning(formapagamentoboletoConf);
        var data = moment(new Date(), "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")

        console.log(emailParticipanteValia);

        var contatoRequest = {
            nome: pessoa.nome,
            email: pessoa.email,
            cpf: pessoa.cpf
        }

        var contato = await AtendimentoAPI.postPessoa(crmToken, contatoRequest);
        var idContato = contato.data.id;


        formapagamentoboletoPayload.crm.id_contato = idContato;
        formapagamentoboletoPayload.crm.matricula = formapagamentoboletoPayload.crm.matricula.replaceAll("[matricula]", pessoa["participante-valia"].matricula);

        formapagamentoboletoPayload.crm.tipo = formapagamentoboletoPayload.crm.tipo.replaceAll("[data]", data);
        formapagamentoboletoPayload.crm.tipo = formapagamentoboletoPayload.crm.tipo.replaceAll("[ip]", ip);

        formapagamentoboletoPayload.crm.encadeamento = formapagamentoboletoPayload.crm.encadeamento.replaceAll("[data]", data);
        formapagamentoboletoPayload.crm.encadeamento = formapagamentoboletoPayload.crm.encadeamento.replaceAll("[ip]", ip);

        AtendimentoAPI.postAtendimentoPadrao(formapagamentoboletoPayload.crm, crmToken)
            .then(response => {
                console.log("CRM");
                console.log(response.data);
                console.log("/CRM");
            })
            .catch(() => {
                //console.log(e);
            });

        if (fluxo != "aberturamaior18" && fluxo != "aberturamenor18" && fluxo != "abertura" && fluxo != "proprio") {
            var contatoResponsavelRequest = {
                nome: pessoa["participante-valia"].nome,
                email: emailParticipanteValia,
                cpf: pessoa["participante-valia"].cpf
            }

            var contatoResponsavel = await AtendimentoAPI.postPessoa(crmToken, contatoResponsavelRequest);
            var idContatoResponsavel = contatoResponsavel.data.id;
            formapagamentoboletoPayload.crm.id_contato = idContatoResponsavel;
            formapagamentoboletoPayload.crm.matricula = formapagamentoboletoPayload.crm.matricula.replaceAll("[matricula]", pessoa["participante-valia"].matricula);

            formapagamentoboletoPayload.crm.tipo = formapagamentoboletoPayload.crm.tipo.replaceAll("[data]", data);
            formapagamentoboletoPayload.crm.tipo = formapagamentoboletoPayload.crm.tipo.replaceAll("[ip]", ip);
            formapagamentoboletoPayload.crm.tipo = formapagamentoboletoPayload.crm.tipo.replaceAll("[participante-patrocinado]", pessoa["participante-valia"].nome);
            formapagamentoboletoPayload.crm.tipo = formapagamentoboletoPayload.crm.tipo.replaceAll("[complemento-dependente]", "Autorização de desconto em folha concedida para o participante prevaler: " + pessoa.nome);

            formapagamentoboletoPayload.crm.encadeamento = formapagamentoboletoPayload.crm.encadeamento.replaceAll("[data]", data);
            formapagamentoboletoPayload.crm.encadeamento = formapagamentoboletoPayload.crm.encadeamento.replaceAll("[ip]", ip);
            formapagamentoboletoPayload.crm.encadeamento = formapagamentoboletoPayload.crm.encadeamento.replaceAll("[participante-patrocinado]", pessoa["participante-valia"].nome);
            formapagamentoboletoPayload.crm.encadeamento = formapagamentoboletoPayload.crm.encadeamento.replaceAll("[complemento-dependente]", "Autorização de desconto em folha concedida para o participante prevaler: " + pessoa.nome);

            AtendimentoAPI.postAtendimentoPadrao(formapagamentoboletoPayload.crm, crmToken)
                .then(response => {
                    console.log("CRM");
                    console.log(response.data);
                    console.log("/CRM");
                })
                .catch(() => {
                    //console.log(e);
                });
        }

        return "ok";
    }


    async formapagamentofolha(fluxo, pessoa, ip, emailRepresentante) {
        var crmToken = await Common.criaTokenCRM();
        var formapagamentofolhaPayload = Common.keepCloning(formapagamentofolhaConf);
        var data = moment(new Date(), "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")
        var participantePatrocinado = pessoa["participante-valia"].nome;


        var email = pessoa.email;
        
        switch (fluxo) {
            case "proprio":
            case "propriodpub":
                break;
            case "maior18":
            case "convidadomaior18":
            case "aberturamaior18":
                email = emailRepresentante;
                break;
            case "menor18":
            case "convidadomenor18":
            case "aberturamenor18":
                email = emailRepresentante;
                break;
        }

        if (fluxo == "aberturamaior18") {
            participantePatrocinado = "";
        }

        formapagamentofolhaPayload.email.corpo = formapagamentofolhaPayload.email.corpo.replaceAll("[participante-patrocinado]", participantePatrocinado);

        if (fluxo == "proprio" || fluxo == "propriodpub") {
            formapagamentofolhaPayload.email.corpo = formapagamentofolhaPayload.email.corpo.replaceAll("[complemento-dependente]", "");
        }
        else {
            formapagamentofolhaPayload.email.corpo = formapagamentofolhaPayload.email.corpo.replaceAll("[complemento-dependente]", " do(a) " + pessoa.nome);
        }

        EmailAPI.postMensagens(formapagamentofolhaPayload.email, email)
            .then(response => {
                console.log(response.data);
            })
            .catch(() => {
                //console.log(e);
            });

        /////////////////////////////////
        ///ATENDIMENTO PADRAO PESSOA
        /////////////////////////////////
        var contatoRequest = {
            nome: pessoa.nome,
            email: email,
            cpf: pessoa.cpf
        }

        var contato = await AtendimentoAPI.postPessoa(crmToken, contatoRequest);
        var idContato = contato.data.id;
        formapagamentofolhaPayload.crm.id_contato = idContato;
        formapagamentofolhaPayload.crm.matricula = formapagamentofolhaPayload.crm.matricula.replaceAll("[matricula]", pessoa["participante-valia"].matricula);

        formapagamentofolhaPayload.crm.tipo = formapagamentofolhaPayload.crm.tipo.replaceAll("[data]", data);
        formapagamentofolhaPayload.crm.tipo = formapagamentofolhaPayload.crm.tipo.replaceAll("[ip]", ip);
        formapagamentofolhaPayload.crm.tipo = formapagamentofolhaPayload.crm.tipo.replaceAll("[participante-patrocinado]", participantePatrocinado);

        formapagamentofolhaPayload.crm.encadeamento = formapagamentofolhaPayload.crm.encadeamento.replaceAll("[data]", data);
        formapagamentofolhaPayload.crm.encadeamento = formapagamentofolhaPayload.crm.encadeamento.replaceAll("[ip]", ip);
        formapagamentofolhaPayload.crm.encadeamento = formapagamentofolhaPayload.crm.encadeamento.replaceAll("[participante-patrocinado]", participantePatrocinado);

        AtendimentoAPI.postAtendimentoPadrao(formapagamentofolhaPayload.crm, crmToken)
            .then(response => {
                console.log("CRM");
                console.log(response.data);
                console.log("/CRM");
            })
            .catch(() => {
                //console.log(e);
            });

        //////////////////////////////////////////
        ///ATENDIMENTO PADRAO RESPONSAVEL
        //////////////////////////////////////////
        //Envia o atendimento padrao para o responsavel tambem nos fluxos do maior e menor
        if (fluxo != "aberturamaior18" && fluxo != "aberturamenor18" && fluxo != "abertura" && fluxo != "proprio") {
            var contatoResponsavelRequest = {
                nome: pessoa["participante-valia"].nome,
                email: emailRepresentante,
                cpf: pessoa["participante-valia"].cpf
            }

            var contatoResponsavel = await AtendimentoAPI.postPessoa(crmToken, contatoResponsavelRequest);
            var idContatoResponsavel = contatoResponsavel.data.id;
            formapagamentofolhaPayload.crmParticipante.id_contato = idContatoResponsavel;
            formapagamentofolhaPayload.crmParticipante.matricula = formapagamentofolhaPayload.crmParticipante.matricula.replaceAll("[matricula]", pessoa["participante-valia"].matricula);

            formapagamentofolhaPayload.crmParticipante.tipo = formapagamentofolhaPayload.crmParticipante.tipo.replaceAll("[data]", data);
            formapagamentofolhaPayload.crmParticipante.tipo = formapagamentofolhaPayload.crmParticipante.tipo.replaceAll("[ip]", ip);
            formapagamentofolhaPayload.crmParticipante.tipo = formapagamentofolhaPayload.crmParticipante.tipo.replaceAll("[participante-patrocinado]", pessoa["participante-valia"].nome);
            formapagamentofolhaPayload.crmParticipante.tipo = formapagamentofolhaPayload.crmParticipante.tipo.replaceAll("[complemento-dependente]", "Autorização de desconto em folha concedida para o participante prevaler: " + pessoa.nome);

            formapagamentofolhaPayload.crmParticipante.encadeamento = formapagamentofolhaPayload.crmParticipante.encadeamento.replaceAll("[data]", data);
            formapagamentofolhaPayload.crmParticipante.encadeamento = formapagamentofolhaPayload.crmParticipante.encadeamento.replaceAll("[ip]", ip);
            formapagamentofolhaPayload.crmParticipante.encadeamento = formapagamentofolhaPayload.crmParticipante.encadeamento.replaceAll("[participante-patrocinado]", pessoa["participante-valia"].nome);
            formapagamentofolhaPayload.crmParticipante.encadeamento = formapagamentofolhaPayload.crmParticipante.encadeamento.replaceAll("[complemento-dependente]", "Autorização de desconto em folha concedida para o participante prevaler: " + pessoa.nome);

            AtendimentoAPI.postAtendimentoPadrao(formapagamentofolhaPayload.crmParticipante, crmToken)
                .then(response => {
                    console.log("CRM");
                    console.log(response.data);
                    console.log("/CRM");
                })
                .catch(() => {
                    //console.log(e);
                });
        }


        return "ok";
    }


    async informacoesperfilinvestimento(fluxo, pessoa, ip, questionario) {
        var crmToken = await Common.criaTokenCRM();
        var informacoesperfilinvestimentoPayload = Common.keepCloning(informacoesperfilinvestimentoConf);
        var data = moment(new Date(), "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")

        var email = pessoa.email;
        var cpf = "";

        console.log(email);
        console.log(cpf);

        switch (fluxo) {
            case "proprio":
            case "propriodpub":
                cpf = pessoa.cpf;
                break;
            case "maior18":
            case "convidadomaior18":
            case "aberturamaior18":
                cpf = pessoa.cpf;
                break;
            case "menor18":
            case "convidadomenor18":
            case "aberturamenor18":
                email = pessoa.representante.email;
                cpf = pessoa.representante.cpf;
                break;
        }

        var contatoRequest = {
            nome: pessoa.nome,
            email: pessoa.email,
            cpf: pessoa.cpf
        }

        var contato = await AtendimentoAPI.postPessoa(crmToken, contatoRequest);
        var idContato = contato.data.id;

        var perguntas = "";

        //Fez questionario
        if (pessoa["perfil-investidor"].perfilSugerido != "") {
            perguntas += (Profile.numeroQuestaoParaTexto(1) + " / " + Profile.numeroQuestaoERespostaParaTextoResposta(1, questionario.questao01) + "; ")
            perguntas += (Profile.numeroQuestaoParaTexto(2) + " / " + Profile.numeroQuestaoERespostaParaTextoResposta(2, questionario.questao02) + "; ")
            perguntas += (Profile.numeroQuestaoParaTexto(3) + " / " + Profile.numeroQuestaoERespostaParaTextoResposta(3, questionario.questao03) + "; ")
            perguntas += (Profile.numeroQuestaoParaTexto(4) + " / " + Profile.numeroQuestaoERespostaParaTextoResposta(4, questionario.questao04) + "; ")
            perguntas += (Profile.numeroQuestaoParaTexto(5) + " / " + Profile.numeroQuestaoERespostaParaTextoResposta(5, questionario.questao05) + "; ")
            perguntas += (Profile.numeroQuestaoParaTexto(6) + " / " + Profile.numeroQuestaoERespostaParaTextoResposta(6, questionario.questao06) + "; ")

            perguntas += ("Perfil sugerido / " + Profile.perfilNumeroParaTexto(pessoa["perfil-investidor"].perfilSugerido).toUpperCase() + "; ")
            perguntas += ("Perfil selecionado / " + Profile.perfilNumeroParaTexto(pessoa["perfil-investidor"].perfilSelecionado).toUpperCase() + "; ")
        }
        else {
            perguntas += "Participante se recusou a responder o questionário e concordou em ser colocado no perfil Prevaler Base. "
        }

        informacoesperfilinvestimentoPayload.crm.id_contato = idContato;

        informacoesperfilinvestimentoPayload.crm.matricula = informacoesperfilinvestimentoPayload.crm.matricula.replaceAll("[matricula]", pessoa["participante-valia"].matricula);

        informacoesperfilinvestimentoPayload.crm.tipo = informacoesperfilinvestimentoPayload.crm.tipo.replaceAll("[data]", data);
        informacoesperfilinvestimentoPayload.crm.tipo = informacoesperfilinvestimentoPayload.crm.tipo.replaceAll("[ip]", ip);
        informacoesperfilinvestimentoPayload.crm.tipo = informacoesperfilinvestimentoPayload.crm.tipo.replaceAll("[perguntas]", perguntas);

        informacoesperfilinvestimentoPayload.crm.encadeamento = informacoesperfilinvestimentoPayload.crm.encadeamento.replaceAll("[data]", data);
        informacoesperfilinvestimentoPayload.crm.encadeamento = informacoesperfilinvestimentoPayload.crm.encadeamento.replaceAll("[ip]", ip);
        informacoesperfilinvestimentoPayload.crm.encadeamento = informacoesperfilinvestimentoPayload.crm.encadeamento.replaceAll("[perguntas]", perguntas);

        AtendimentoAPI.postAtendimentoPadrao(informacoesperfilinvestimentoPayload.crm, crmToken)
            .then(response => {
                console.log("CRM");
                console.log(response.data);
                console.log("/CRM");
            })
            .catch(() => {
                //console.log(e);
            });

        return "ok";
    }


    async adesaopendenteanalise(fluxo, pessoa, parentesco, ip, smsCode) {
        var crmToken = await Common.criaTokenCRM();
        var adesaopendenteanalisePayload = Common.keepCloning(adesaopendenteanaliseConf);
        var data = moment(new Date(), "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")
        var parentescoTexto = "";

        var codparentesco = pessoa["participante-valia"].parentesco;
        var menorIdade = pessoa["menor-idade"];

        if (codparentesco == "2" && menorIdade) {
            parentescoTexto = "Nome do filho menor: " + pessoa.nome;
        }
        else if (codparentesco == "99") {
            parentescoTexto = "Auto Inscrição: " + pessoa["participante-valia"].nome;
        }
        else {
            parentescoTexto = "Parentesco com o Participante: " + parentesco;
        }



        var email = pessoa.email;
        var nome = "";
        var cpf = "";


        console.log(email);
        console.log(cpf);

        switch (fluxo) {
            case "proprio":
            case "propriodpub":
                nome = pessoa.nome.split(" ")[0].initCap();
                cpf = pessoa.cpf;
                break;
            case "maior18":
            case "convidadomaior18":
            case "aberturamaior18":
                nome = pessoa.nome.split(" ")[0].initCap();
                cpf = pessoa.cpf;
                break;
            case "menor18":
            case "convidadomenor18":
            case "aberturamenor18":
                nome = pessoa.nome.split(" ")[0].initCap();  //nome = pessoa["participante-valia"].nome.split(" ")[0].initCap();
                email = pessoa.representante.email;
                cpf = pessoa.representante.cpf;
                break;
        }

        adesaopendenteanalisePayload.email.corpo = adesaopendenteanalisePayload.email.corpo.replaceAll("[nome]", nome);
        adesaopendenteanalisePayload.email.corpo = adesaopendenteanalisePayload.email.corpo.replaceAll("[protocolo]", pessoa["detalhesDaAdesao"].protocolo);

        EmailAPI.postMensagens(adesaopendenteanalisePayload.email, email)
            .then(response => {
                console.log(response.data);
            })
            .catch(() => {
                //console.log(e);
            });


        var contatoRequest = {
            nome: pessoa.nome,
            email: pessoa.email,
            cpf: pessoa.cpf
        }

        var contato = await AtendimentoAPI.postPessoa(crmToken, contatoRequest);
        var idContato = contato.data.id;


        adesaopendenteanalisePayload.crm.id_contato = idContato;
        adesaopendenteanalisePayload.crm.matricula = adesaopendenteanalisePayload.crm.matricula.replaceAll("[matricula]", pessoa["participante-valia"].matricula);

        adesaopendenteanalisePayload.crm.tipo = adesaopendenteanalisePayload.crm.tipo.replaceAll("[data]", data);
        adesaopendenteanalisePayload.crm.tipo = adesaopendenteanalisePayload.crm.tipo.replaceAll("[ip]", ip);
        adesaopendenteanalisePayload.crm.tipo = adesaopendenteanalisePayload.crm.tipo.replaceAll("[parentesco]", parentescoTexto);
        adesaopendenteanalisePayload.crm.tipo = adesaopendenteanalisePayload.crm.tipo.replaceAll("[token]", smsCode);

        adesaopendenteanalisePayload.crm.encadeamento = adesaopendenteanalisePayload.crm.encadeamento.replaceAll("[data]", data);
        adesaopendenteanalisePayload.crm.encadeamento = adesaopendenteanalisePayload.crm.encadeamento.replaceAll("[ip]", ip);
        adesaopendenteanalisePayload.crm.encadeamento = adesaopendenteanalisePayload.crm.encadeamento.replaceAll("[parentesco]", parentescoTexto);
        adesaopendenteanalisePayload.crm.encadeamento = adesaopendenteanalisePayload.crm.encadeamento.replaceAll("[token]", smsCode);

        AtendimentoAPI.postAtendimentoPadrao(adesaopendenteanalisePayload.crm, crmToken)
            .then(response => {
                console.log("CRM");
                console.log(response.data);
                console.log("/CRM");
            })
            .catch(() => {
                //console.log(e);
            });


        return "ok";
    }

    async protocoloadesaoAbertura(fluxo, pessoa, parentesco, entrypoint) {
        var crmToken = await Common.criaTokenCRM();
        var protocoloadesaoAberturaPayload = Common.keepCloning(protocoloadesaoAberturaConf);

        var email = pessoa.email;
        var nome = "";
        var cpf = "";

        switch (fluxo) {
            case "aberturamaior18":
                nome = pessoa.nome.split(" ")[0].initCap();
                cpf = pessoa.cpf;
                break;
            case "aberturamenor18":
                nome = pessoa.nome.split(" ")[0].initCap();  //nome = pessoa["participante-valia"].nome.split(" ")[0].initCap();
                email = pessoa.representante.email;
                cpf = pessoa.representante.cpf;
                break;
        }

        console.log(cpf);
        console.log(parentesco)

        var contatoRequest = {
            nome: pessoa.nome,
            email: pessoa.email,
            cpf: pessoa.cpf
        }

        var contato = await AtendimentoAPI.postPessoa(crmToken, contatoRequest);
        var idContato = contato.data.id;


        protocoloadesaoAberturaPayload.email.corpo = protocoloadesaoAberturaPayload.email.corpo.replaceAll("[nome]", nome);
        protocoloadesaoAberturaPayload.email.corpo = protocoloadesaoAberturaPayload.email.corpo.replaceAll("[protocolo]", pessoa["detalhesDaAdesao"].protocolo);

        EmailAPI.postMensagens(protocoloadesaoAberturaPayload.email, email)
            .then(response => {
                console.log(response.data);
            })
            .catch(() => {
                //console.log(e);
            });


        protocoloadesaoAberturaPayload.crm.id_contato = idContato;
        protocoloadesaoAberturaPayload.crm.matricula = protocoloadesaoAberturaPayload.crm.matricula.replaceAll("[matricula]", "");

        protocoloadesaoAberturaPayload.crm.tipo = protocoloadesaoAberturaPayload.crm.tipo.replaceAll("[protocolo]", pessoa["detalhesDaAdesao"].protocolo);
        protocoloadesaoAberturaPayload.crm.tipo = protocoloadesaoAberturaPayload.crm.tipo.replaceAll("[matricula]", "");
        protocoloadesaoAberturaPayload.crm.tipo = protocoloadesaoAberturaPayload.crm.tipo.replaceAll("[cpf]", cpf);
        protocoloadesaoAberturaPayload.crm.tipo = protocoloadesaoAberturaPayload.crm.tipo.replaceAll("[entrypoint]", entrypoint);

        protocoloadesaoAberturaPayload.crm.encadeamento = protocoloadesaoAberturaPayload.crm.encadeamento.replaceAll("[protocolo]", pessoa["detalhesDaAdesao"].protocolo);
        protocoloadesaoAberturaPayload.crm.encadeamento = protocoloadesaoAberturaPayload.crm.encadeamento.replaceAll("[matricula]", "");
        protocoloadesaoAberturaPayload.crm.encadeamento = protocoloadesaoAberturaPayload.crm.encadeamento.replaceAll("[cpf]", cpf);
        protocoloadesaoAberturaPayload.crm.encadeamento = protocoloadesaoAberturaPayload.crm.encadeamento.replaceAll("[entrypoint]", entrypoint);

        AtendimentoAPI.postAtendimentoPadrao(protocoloadesaoAberturaPayload.crm, crmToken)
            .then(response => {
                console.log("CRM");
                console.log(response.data);
                console.log("/CRM");
            })
            .catch(() => {
                //console.log(e);
            });


        return "ok";
    }

    async protocolocriacaoadesaoAbertura(fluxo, pessoa, parentesco, ip) {
        var crmToken = await Common.criaTokenCRM();
        var protocolocriacaoadesaoAberturaPayload = Common.keepCloning(protocolocriacaoadesaoAberturaConf);
        var email = pessoa.email;
        var nome = "";
        var cpf = "";

        var data = moment(new Date(), "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")

        console.log(parentesco);
        console.log(email);

        switch (fluxo) {
            case "aberturamaior18":
                nome = pessoa.nome.split(" ")[0].initCap();
                cpf = pessoa.cpf;
                break;
            case "aberturamenor18":
                nome = pessoa.nome.split(" ")[0].initCap();  
                email = pessoa.representante.email;
                cpf = pessoa.representante.cpf;
                break;
        }

        console.log(cpf);

        var contatoRequest = {
            nome: pessoa.nome,
            email: pessoa.email,
            cpf: pessoa.cpf
        }

        var contato = await AtendimentoAPI.postPessoa(crmToken, contatoRequest);
        var idContato = contato.data.id;

        protocolocriacaoadesaoAberturaPayload.crm.id_contato = idContato;
        protocolocriacaoadesaoAberturaPayload.crm.matricula = protocolocriacaoadesaoAberturaPayload.crm.matricula.replaceAll("[matricula]", "");

        protocolocriacaoadesaoAberturaPayload.crm.tipo = protocolocriacaoadesaoAberturaPayload.crm.tipo.replaceAll("[protocolo]", pessoa["detalhesDaAdesao"].protocolo);
        protocolocriacaoadesaoAberturaPayload.crm.tipo = protocolocriacaoadesaoAberturaPayload.crm.tipo.replaceAll("[nome]", nome);
        protocolocriacaoadesaoAberturaPayload.crm.tipo = protocolocriacaoadesaoAberturaPayload.crm.tipo.replaceAll("[data]", data);
        protocolocriacaoadesaoAberturaPayload.crm.tipo = protocolocriacaoadesaoAberturaPayload.crm.tipo.replaceAll("[ip]", ip);
        
        protocolocriacaoadesaoAberturaPayload.crm.encadeamento = protocolocriacaoadesaoAberturaPayload.crm.encadeamento.replaceAll("[protocolo]", pessoa["detalhesDaAdesao"].protocolo);
        protocolocriacaoadesaoAberturaPayload.crm.encadeamento = protocolocriacaoadesaoAberturaPayload.crm.encadeamento.replaceAll("[nome]", nome);
        protocolocriacaoadesaoAberturaPayload.crm.encadeamento = protocolocriacaoadesaoAberturaPayload.crm.encadeamento.replaceAll("[data]", data);
        protocolocriacaoadesaoAberturaPayload.crm.encadeamento = protocolocriacaoadesaoAberturaPayload.crm.encadeamento.replaceAll("[ip]", ip);

        AtendimentoAPI.postAtendimentoPadrao(protocolocriacaoadesaoAberturaPayload.crm, crmToken)
            .then(response => {
                console.log("CRM");
                console.log(response.data);
                console.log("/CRM");
            })
            .catch(() => {
                //console.log(e);
            });


        return "ok";
    }

}

export default new NotificationService();