<template>
    <transition name="slide-up">
        <div class="content-container content-half" v-if="isActive">
            <div class="content-small">
                <div class="terminando-img d-flex justify-content-center">
                    <img src="/img/icon-terminando.png" />
                </div>

                <div class="content-title">
                    <div class="fs-36px fw-700 color-primary text-center mb-4">Estamos quase terminando!</div>
                </div>

                <div class="content-contents d-flex flex-column align-items-center">

                    <div class="fs-24px fw-700 color-dark text-center mb-5">Agora você pode:</div>

                    <button @click="concluir()" id="concluir_adesao" class="button-primary-bg-primary btn-250-width action-button">
                        Concluir a adesão
                    </button>

                    <div class="mt-4 mb-4 ml-5">
                        <div class="fs-18px fw-700 color-gray-dark  text-left mb-3 ml-4">Ou continuar para:</div>
                        <ul>
                            <li class="fw-700 color-gray-dark ">
                                <p class="fs-18px">Definir a idade para receber o benefício</p>
                            </li>
                            <li class="fw-700 color-gray-dark ">
                                <p class="fs-18px">Definir beneficiários</p>
                            </li>
                        </ul>
                    </div>
                    <button @click="definirIdadeEBeneficiarios()" type="button" class="btn fill-after btn-250-width action-button">
                        Continuar
                    </button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import 'bootstrap';
    import moment from 'moment';

    export default {
        name: 's07-quase-terminando',
        props: ['pessoa', 'visible'],
        data: () => ({
            isActive: false,
            loading: false,
            selection: 1,
            step: 1,
        }),
        watch: {
            visible: function () {
                this.toggle();
            }
        },
        methods: {
            toggle() {
                this.isActive = !this.isActive;
                 
                if (this.pessoa["perfil-plano"] !== undefined && (this.pessoa["perfil-plano"]["idade-aposentadoria"] === undefined || this.pessoa["perfil-plano"]["idade-aposentadoria"] == null || this.pessoa["perfil-plano"]["idade-aposentadoria"] == 0 || this.pessoa["perfil-plano"]["idade-aposentadoria"] == "0")) {
                    var date = moment(this.pessoa.dataNascimento, "DD/MM/YYYY");

                    var age = this.calculate_age(date.toDate());

                    if (age < 45)
                        age = 50;
                    else
                        age = age + 5;

                    if (age > 0)
                        this.pessoa["perfil-plano"]["idade-aposentadoria"] = age.toString();
                }
            },
            concluir() {
                this.pessoa["indicar-beneficiario"] = "depois";
                this.$emit('concluir');
            },
            next: function () {
                this.$emit('next');
            },
            back: function () {
                this.$emit('back');
            },
            definirIdadeEBeneficiarios: function () {
                this.$emit('definirIdadeEBeneficiarios');
            },
            calculate_age: function (dob) {
                var diff_ms = Date.now() - dob.getTime();
                var age_dt = new Date(diff_ms);

                return Math.abs(age_dt.getUTCFullYear() - 1970);
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

