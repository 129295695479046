<template>
    <div>
        <transition name="slide-up">


            <div class="content-container content-half  multiselect-container content-space-between" v-if="isActive">
                <div class="padding-div content-space-between-height">
                    <div class="content-title">
                        <div class="fs-32px fw-700 color-dark mb-2">Indique o percentual dos benefícios.</div>
                        <div class="fs-18px fw-400 color-gray mb-5">A soma do percentual designado de todos os beneficiários deve ser igual a 100%.</div>
                        <div>
                            <p class="color-white">.</p>
                            <div class="mt-3" v-for="d in beneficiariosTemp" v-bind:key="d.cpf">
                                <div class="row">
                                    <div class="col-xl-1 col-2">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" v-show="false" v-model="d.checked">
                                            <button type="button" id="btnAbreModalConfirmaExclusao" @click="setCPF(d.cpf)" v-show="true" class="mr-button mr-3" data-bs-toggle="modal" data-bs-target="#modalExclusao">
                                                <img src="/img/lixeira.svg" />
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-xl-11 col-10">
                                        <label class="form-check-label fs-24px fw-500 mb-3">
                                            {{d.nome}}
                                        </label>
                                        <v-row>
                                            <!-- align-items-end-->
                                            <v-col>
                                                <v-sheet>
                                                    <div class="fs-18px fw-700 color-gray ">CPF</div>
                                                    <div class="fs-18px fw-500 mt-1 color-gray ">{{d.cpf.BRCPF()}}</div>
                                                </v-sheet>
                                            </v-col>
                                            <v-col>
                                                <v-sheet>
                                                    <!--class="pa-2 ma-2"-->
                                                    <div class="fs-18px fw-700 color-gray ">Data de nascimento</div>
                                                    <div class="fs-18px fw-500 color-gray mt-1">
                                                        {{d.dataNascimento}}
                                                    </div>
                                                </v-sheet>
                                            </v-col>
                                            <v-col>
                                                <v-sheet>
                                                    <div class="fs-16px fw-700 color-gray ">Percentual Distribuído</div>
                                                    <div class="d-flex">
                                                        <div class="">
                                                            <!--<input type="text" class="input" id="nome" required placeholder="Digite sua resposta aqui">-->
                                                            <v-text-field-money class="beneficiarios-input" v-model="d.percentualBeneficio" placeholder="Digite sua resposta aqui" @keyup.native.enter="next" v-on:keyup.enter="next"
                                                                                v-bind:properties="{
        readonly: false,
        disabled: false,
        outlined: false,
        clearable: true,
        placeholder: ' ',
      }"
                                                                                v-bind:options="{
        locale: 'pt-BR',
        length: 3,
        precision: 0,
        empty: null,
      }" />
                                                        </div>

                                                    </div>
                                                </v-sheet>
                                            </v-col>
                                        </v-row>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="content-buttons">


                        <div class="div-warning" v-if="percUtilizado>100">
                            <v-icon color="orange" class="f-l">info_outline</v-icon>
                            <div class="fs-20px color-dark f-l ml-2">Você excedeu o percentual disponível para os beneficiários.</div>
                        </div>
                        <div class="div-warning" v-if="isReady && (this.beneficiariosTemp===undefined || this.beneficiariosTemp == null || this.beneficiariosTemp.length == 0)">
                            <v-icon color="orange" class="f-l">info_outline</v-icon>
                            <div class="fs-20px color-dark f-l ml-2">Nenhum beneficiário cadastrado.</div>
                        </div>
                        <div v-if="isReady && beneficiariosTemp!=null && beneficiariosTemp.length>0">
                            <v-progress-linear v-bind:value="percUtilizado"
                                               v-bind:color="percUtilizado<=100 ? '#006ED2':'#ff5252'" background-color="#c4c4c4"
                                               height="35">
                                <template v-slot:default="{ value }">
                                    <strong class="fw-500 fs-20px w-50 ml-3">Total de percentual distribuído</strong>
                                    <div class="w-50">
                                        <div class="a-r">
                                            <strong class="fw-500 fs-20px mr-3">{{ Math.ceil(value) }}/100%</strong>
                                        </div>
                                    </div>
                                </template>
                            </v-progress-linear>
                        </div>
                    </div>
                    <div class="content-buttons">
                        <div class="d-flex align-items-center justify-content-end flex-column-mobile btns-termos">
                            <button type="button" class="btn fill-after  mr-button mr-3 action-button" @click="clearMdata()" data-bs-toggle="modal" data-bs-target="#modalAdicionarBeneficiario">
                                Adicionar novo Beneficiário
                            </button>
                            <button @click="next" class="button-primary-bg-primary action-button">
                                Finalizar
                                <v-icon right>east</v-icon>
                            </button>
                            <button type="button" id="btnAbreModalAlerta" v-show="false" class="btn fill-after mr-button mr-3" data-bs-toggle="modal" data-bs-target="#modalPercentual">
                                Abrir modal Alerta
                            </button>
                           
                        </div>
                    </div>

                </div>
            </div>


        </transition>
        <div class="modal fade valia-modal" style="z-index:3" id="modalAdicionarBeneficiario" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalAdicionarBeneficiario" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" style="z-index:99 !important;">
                <div class="modal-content">

                    <div class="modal-body">
                        <div class="fs-24px color-primary fw-700 text-center">Adicionar beneficiário</div>
                        <div class="content-contents mt-5">
                            <v-form ref="form">
                                <div class="mb-4">
                                    <div class="d-flex justify-content-between">
                                        <div class="w-100 padding-left padding-right">
                                            <div class="fs-24px fw-500 color-dark">Nome do beneficiário</div>
                                            <div class="single-input">
                                                <v-text-field required :rules="[rules.required, rules.onlyalpha]" class="input-caps" v-model="mdata.nome" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="mb-4">
                                    <div class="d-flex justify-content-between align-items-end">
                                        <div class="w-100 padding-left padding-right">
                                            <div class="fs-24px fw-500 color-dark">CPF</div>
                                            <div class="single-input">
                                                <v-text-field-simplemask required :rules="[rules.required, rules.validcpf, rules.cpfunico]" v-model="mdata.cpf"
                                                                         v-bind:properties="{
        prefix: '',
        suffix: '',
        readonly: false,
        disabled: false,
        outlined: false,
        clearable: true,
        placeholder: '',
      }"
                                                                         v-bind:options="{
        inputMask: '###.###.###-##',
        outputMask: '###.###.###-##',
        empty: null,
        applyAfter: false,
        alphanumeric: false,
        lowerCase: false,
      }" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <div class="d-flex justify-content-between align-items-end">
                                        <div class="w-100 padding-left padding-right">
                                            <div class="fs-24px fw-500 color-dark">Data de nascimento</div>
                                            <div class="single-input">
                                                <v-text-field-simplemask required :rules="[rules.required, rules.validdate]" v-model="mdata.dataNascimento"
                                                                         v-bind:properties="{
        prefix: '',
        suffix: '',
        readonly: false,
        disabled: false,
        outlined: false,
        clearable: true,
        placeholder: '',
      }"
                                                                         v-bind:options="{
        inputMask: '##/##/####',
        outputMask: '##/##/####',
        empty: null,
        applyAfter: false,
        alphanumeric: false,
        lowerCase: false,
      }" />


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <div class="d-flex justify-content-between align-items-end">

                                        <div class="w-100 padding-left padding-right">
                                            <div class="fs-24px fw-500 color-dark">Sexo</div>
                                            <div class="n-form-select">
                                                <v-select :rules="[rules.required]" v-bind:items="sexos" v-model="mdata.sexo" item-value="value" item-text="text"></v-select>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </v-form>
                        </div>
                        <div class="mt-5 d-flex flex-column align-items-center">
                            <div class="col-xl-6 col-lg-6 col-md-8 col-sm-12">

                                <button class="button-primary-bg-primary w-100 modal-button" @click="adicionarBeneficiario()">
                                    Adicionar
                                </button>
                                <button type="button" id="closeModalAdicionarBeneficiario" class="btn fill-after-w-100 mt-3 w-100 modal-button" data-bs-dismiss="modal">
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="modal fade valia-modal" style="z-index:3" id="modalPercentual" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalPercentual" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" style="z-index:99 !important;">
                <div class="modal-content">

                    <div class="modal-body">
                        <div class="fs-24px color-primary fw-700 text-center">Atenção!</div>
                        <div class="mt-3">
                            <div class="fs-18px">
                                <!--Olá participante! Para continuar, distribua o percentual total de 100% a um ou mais beneficiários, caso sua escolha seja atribuir beneficiário ao seu cadastro.-->
                                Preencha o percentual distribuído para todos os beneficiários selecionados
                            </div>
                        </div>
                        <div class="mt-5 d-flex flex-column align-items-center">
                            <div class="col-xl-6 col-lg-6 col-md-8 col-sm-12">
                                <button class="button-primary-bg-primary w-100 modal-button" data-bs-dismiss="modal">
                                    Continuar
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="modal fade valia-modal" style="z-index:3" id="modalExclusao" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalExclusao" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" style="z-index:99 !important;">
                <div class="modal-content">

                    <div class="modal-body">
                        <div class="fs-24px color-primary fw-700 text-center">Atenção!</div>
                        <div class="mt-3">
                            <div class="fs-18px">
                                Tem certeza que deseja excluir o beneficiário? Para voltar com o beneficiário para a lista, você terá que cadastra-lo novamente.
                            </div>
                        </div>
                        <div class="mt-5 d-flex flex-column align-items-center">
                            <div class="col-xl-6 col-lg-6 col-md-8 col-sm-12">

                                <button class="button-primary-bg-primary w-100 modal-button" @click="confirmaExclusao()">
                                    Excluir beneficiário
                                </button>
                                <button type="button" id="closeModalConfirmaExclusao" class="btn fill-after-w-100 mt-3 w-100 modal-button" data-bs-dismiss="modal">
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import 'bootstrap';
    import { mapState } from "vuex";
    import CorporativoAPI from '../../services/CorporativoAPI';
    import Enumerable from "linq";
    import moment from 'moment';

    import SimpleMask from "../../components/SimpleMask.vue";
    import Decimal from "../../components/Decimal.vue";
    import StepTracker from "../../services/StepTracker";

    export default {
        name: 's09-cadastrar-beneficiario-2',
        props: ['pessoa', 'visible', 'number'],
        components: {
            "v-text-field-simplemask": SimpleMask,
            "v-text-field-money": Decimal,
        },
        data: () => ({
            isActive: false,
            isReady: false,
            hasErrors: false,
            selection: 1,
            step: 1,
            dependentesOrigem: [],
            beneficiariosTemp: [],
            beneficiarios: [],
            mdata: {
                nome: null,
                cpf: null,
                dataNascimento: null,
                sexo: null
            },
            sexos: [
                { text: 'Masculino', value: "masculino" },
                { text: 'Feminino', value: "feminino" }],
            hasMDataErrors: false,
            rules: {
            },
            cpfToDelete: null,
            trackMsg: null,
        }),
        watch: {
            visible: function () {
                this.toggle();
            },

        },
        computed: {
            ...mapState(["pessoaCorporativo"]),
            percUtilizado() {

                var ben = Enumerable.From(this.beneficiariosTemp)
                    .Where("$.checked == true").ToArray();

                var soma = 0;

                if (ben != null) {
                    for (var i = 0; i < ben.length; i++) {
                        var t = ben[i];

                        var percBen = (t.percentualBeneficio == null ? 0 : t.percentualBeneficio);
                        var percBenTratado = (percBen == "") ? percBen : percBen.toString().replace(".", "").replace("-", "");

                        soma = soma + parseInt(percBenTratado);
                    }
                }

                if (isNaN(soma))
                    return 0;

                return soma;
            }
        },
        methods: {
            toggle() {
                this.isActive = !this.isActive;
                if (this.isActive) {
                    this.habilitaOpcoes();
                }
                this.trackMsg = null;
            },
            next: function (event) {
                event.preventDefault();
                var v = this.percUtilizado;


                var ben = Enumerable.From(this.beneficiariosTemp)
                    .Where("$.checked == true").ToArray();
                if (v != 100 && ben != null && ben.length > 0) {
                    document.getElementById('btnAbreModalAlerta').click();
                    this.hasErrors = true;
                    return false;
                }

                this.beneficiarios = [];

                if (ben != null) {
                    for (var i = 0; i < ben.length; i++) {
                        var t = ben[i];

                        if (t.percentualBeneficio == null || t.percentualBeneficio == "" || t.percentualBeneficio == 0) {
                            document.getElementById('btnAbreModalAlerta').click();
                            this.hasErrors = true;
                            return false;
                        }

                        this.beneficiarios.push({
                            "nome": t.nome,
                            "cpf": t.cpf.BRCPF(), //"169.516.638-82",
                            "data-nascimento": t.dataNascimento,
                            "sexo": t.sexo,
                            "percentual-beneficio": ((t.percentualBeneficio == null || t.percentualBeneficio == "") ? t.percentualBeneficio : t.percentualBeneficio.toString().replace(".", "").replace("-", "")),
                            "checked": true

                        });
                    }
                    this.pessoa.beneficiarios = this.beneficiarios;
                }

                this.hasErrors = false;
                StepTracker.registerStep(this.number, this.trackMsg);
                this.$emit('next');
            },
            back: function () {
                this.$emit('back');
            },
            async habilitaOpcoes() {
                this.beneficiariosTemp = [];
                if (this.pessoa["beneficiarios"] !== undefined) {
                    this.beneficiarios = this.pessoa["beneficiarios"];
                }
                if (this.beneficiarios != null) {
                    for (var ib = 0; ib < this.beneficiarios.length; ib++) {
                        var tb = this.beneficiarios[ib];
                        this.beneficiariosTemp.push({
                            "checked": true,
                            "nome": tb.nome,
                            "cpf": tb.cpf,
                            "dataNascimento": tb["data-nascimento"],
                            "sexo": ((tb.sexo == "M" || tb.sexo == "m" || tb.sexo == "Masculino" || tb.sexo == "masculino") ? "masculino" : "feminino"),
                            "percentualBeneficio": parseInt(tb["percentual-beneficio"])
                        });
                    }
                }
                if (this.pessoaCorporativo != null && StepTracker.returnStep(this.number) == null) {
                    await CorporativoAPI.getDependentesAllDetalhado(this.pessoaCorporativo.codempresa, this.pessoaCorporativo.registro).then(response => {
                        this.dependentesOrigem = response.data.resultado;
                    })
                        .catch(e => {
                            console.log(e);
                        });
                    if (this.dependentesOrigem != null) {
                        for (var i = 0; i < this.dependentesOrigem.length; i++) {
                            var t = this.dependentesOrigem[i];

                            var obj = Enumerable.From(this.beneficiariosTemp).
                                Where("$.nome == '" + t.NOM_DPDTE + "'").FirstOrDefault();

                            if (obj == null && (t.NOM_DPDTE != this.pessoa.nome && moment(new Date(t.DAT_NASCT_DPDTE), "DD/MM/YYYY").format("DD/MM/YYYY") != moment(new Date(this.pessoa.dataNascimento), "DD/MM/YYYY").format("DD/MM/YYYY")))
                                this.beneficiariosTemp.push({
                                    "checked": true,
                                    "nome": t.NOM_DPDTE,
                                    "cpf": t.NUM_CPF_DPDTE,
                                    "dataNascimento": moment(new Date(t.DAT_NASCT_DPDTE), "DD/MM/YYYY").format("DD/MM/YYYY"), //"17/11/1973",
                                    "sexo": ((t.COD_SEXO == "M" || t.COD_SEXO == "m" || t.COD_SEXO == "Masculino" || t.COD_SEXO == "masculino") ? "masculino" : "feminino"),// "masculino",
                                    "percentualBeneficio": null
                                });
                        }
                    }
                }
                this.isReady = true;
            },
            
            adicionarBeneficiario: async function () {
                //event.preventDefault();

                this.rules = {                   
                    required: value => !!value || 'Campo obrigatório.',
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'E-mail inválido.'
                    },
                    validdate: v => {
                        if (v == null) return true;

                        var s = v.split('/');
                        var d = new Date(+s[2], s[1] - 1, +s[0]);
                        var ok = false;
                        if (Object.prototype.toString.call(d) === "[object Date]") {
                            if (!isNaN(d.getTime()) && d.getDate() == s[0] &&
                                d.getMonth() == (s[1] - 1)) {
                                ok = true;
                                //return true;
                            }
                        }
                        if (ok && (parseInt(+s[2]) < 1000 || d.getFullYear() < 1000))
                            ok = false;

                        if (!ok)
                            return "Data inválida";

                    },
                    validcpf: ii => {
                        if (ii == null) return true;

                        var c = ii.replace(/[^\d]/g, "");
                        if (c.length != 11)
                            return "CPF inválido";

                        if (c == "00000000000" ||
                            c == "11111111111" ||
                            c == "22222222222" ||
                            c == "33333333333" ||
                            c == "44444444444" ||
                            c == "55555555555" ||
                            c == "66666666666" ||
                            c == "77777777777" ||
                            c == "88888888888" ||
                            c == "99999999999")
                            return "CPF inválido";

                        var r;
                        var s = 0;

                        for (i = 1; i <= 9; i++)
                            s = s + parseInt(c[i - 1]) * (11 - i);

                        r = (s * 10) % 11;

                        if ((r == 10) || (r == 11))
                            r = 0;

                        if (r != parseInt(c[9]))
                            return "CPF inválido";

                        s = 0;

                        for (var i = 1; i <= 10; i++)
                            s = s + parseInt(c[i - 1]) * (12 - i);

                        r = (s * 10) % 11;

                        if ((r == 10) || (r == 11))
                            r = 0;

                        if (r != parseInt(c[10]))
                            return "CPF inválido";

                        return true;
                    },
                    cpfunico: v => {
                        var existentes = Enumerable.From(this.beneficiariosTemp)
                            .Where("$.cpf == '" + v + "'").ToArray();

                        if (existentes != null && existentes.length > 0) {
                            return "CPF já cadastrado";
                        }
                        const cpfPessoaLimpo = v.replace(/[.-]/g, '');

                        if (this.pessoa.cpf === cpfPessoaLimpo) {
                            return "Você não pode escolher a si mesmo como beneficiário.";
                        }
                    },
                    onlyalpha: value => {
                        const pattern = /^[A-Za-zà-úá-úâ-ûä-üã-õçñÀ-ÙÁ-ÚÂ-ÛÄ-ÜÃ-ÕÇÑ ]+$/
                        return pattern.test(value) || 'Preencha somente com letras, sem caracteres especiais.'
                    },
                }

                this.$nextTick(() => {
                    if (this.$refs.form.validate()) {

                        this.hasMDataErrors = false;
                        this.beneficiariosTemp.push({
                            "checked": true,
                            "nome": this.mdata.nome.capitalize(),
                            "cpf": this.mdata.cpf,
                            "dataNascimento": this.mdata.dataNascimento, //moment(new Date(t.DAT_NASCT_DPDTE), "DD/MM/YYYY").format("DD/MM/YYYY"), //"17/11/1973",
                            "sexo": this.mdata.sexo,
                            "percentualBeneficio": null
                        });
                        document.getElementById('closeModalAdicionarBeneficiario').click();
                    }
                })
            },
            clearMdata: function () {
                this.mdata.nome = null;
                this.mdata.cpf = null;
                this.mdata.dataNascimento = null;
                this.mdata.sexo = null;
                this.rules = {};
                this.$refs.form.resetValidation();
            },
            setCPF: async function (cpf) {
                this.cpfToDelete = cpf;
            },
            confirmaExclusao: async function () { 
                var remanescentes = Enumerable.From(this.beneficiariosTemp)
                    .Where("$.cpf != '" + this.cpfToDelete + "'").ToArray();
                this.beneficiariosTemp = remanescentes;

                document.getElementById('closeModalConfirmaExclusao').click();
            }, 
        },
    };
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

