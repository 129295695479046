import CryptoJS from "crypto-js";
import SeguridadeAPI from '../SeguridadeAPI';
import IPIFYAPI from "../IPIFYAPI";
import CorporativoAPI from '../CorporativoAPI';
import PlanoFamiliaAPI from '../PlanoFamiliaAPI';
import AtendimentoAPI from '../AtendimentoAPI';
import store from '../../store';
import moment from 'moment';
import Enumerable from "linq";
import Vue from 'vue';
class Common {

    async loadInitialDataAbertura() {


        IPIFYAPI.getIPAddress().then(response => {
            var luserIP = response.data.ip.toString();
            store.commit("saveUserIP", luserIP.toString());
        })
            .catch(e => {
                console.log(e);
            });

        CorporativoAPI.getEmpresasHabilitadasDescontoFolha().then(response => {
            store.commit("saveEmpresasDesconto", response.data);
        })
            .catch(e => {
                console.log(e);
            });
    }

    async loadInitialData() {
        CorporativoAPI.getGrausParentesco().then(response => {
            store.commit("saveGrausParentesco", response.data.resultado);
        })
            .catch(e => {
                console.log(e);
            });



        IPIFYAPI.getIPAddress().then(response => {
            var luserIP = response.data.ip.toString();
            store.commit("saveUserIP", luserIP.toString());
        })
            .catch(e => {
                console.log(e);
            });
    }

    async criaTokenCRM() {
        var ret = null;
        await AtendimentoAPI.postSessoes().then(response => {
            ret = response.data.token;
        })
            .catch(e => {
                console.log(e);
            });
        return ret;
    }
    async loadPessoa(cpf, protocolo) {

        var pessoa = null;
        var perfilInvestidor = null;

        var p = null;
        await PlanoFamiliaAPI.getPessoasAll(cpf, protocolo)
            .then(response => {
                p = response.data.Documents[0];
            })
            .catch(e => {
                console.log(e);
            });


        await PlanoFamiliaAPI.getPessoas(p.id, p.cpf).then(response => {
            pessoa = response.data;
            perfilInvestidor = pessoa["perfil-investidor"];
            if (perfilInvestidor == null)
                perfilInvestidor = {};

            var date = moment(pessoa.dataNascimento, "DD/MM/YYYY");
            var age = this.calculate_age(date.toDate());
            if (age < 18) {
                pessoa["menor-idade"] = "sim";
            }
            else {
                pessoa["menor-idade"] = "nao";
            }

            pessoa["perfil-investidor"] = perfilInvestidor;


            if (pessoa["perfil-pessoal"] === undefined || pessoa["perfil-pessoal"] == null)
                pessoa["perfil-pessoal"] = {};

            if (pessoa["perfil-pessoal"].identificacao === undefined || pessoa["perfil-pessoal"].identificacao == null)
                pessoa["perfil-pessoal"].identificacao = {};

            if (pessoa["perfil-pessoal"].identificacao["nome-pai"] !== undefined && pessoa["perfil-pessoal"].identificacao["nome-pai"] != null) {
                pessoa["perfil-pessoal"].identificacao["pai-nao-declarado"] = false;
            }
            else {
                pessoa["perfil-pessoal"].identificacao["pai-nao-declarado"] = true;
            }
        })
            .catch(e => {
                console.log(e);
            });

        return pessoa;
    }

    async loadPessoaCorporativo(pessoa, bypassLoadProfile) {
        var matricula = pessoa["participante-valia"].matricula;
        var cpf = pessoa.cpf;
        var token = "";
        await SeguridadeAPI.postSessoes().then(response => {
            if (response.data !== undefined && response.data.Resultado !== undefined) {
                token = response.data.Resultado.Token;
                store.commit("saveToken", token);
            }
            else {
                console.log("erro");
            }
        })
            .catch(e => {
                console.log(e);
            });
        var isParticipante = false;
        var isDependenteOrRepresentante = false;

        //RECUPERA OS DADOS DE ACORDO COM O PERFIL
        //TODO: se identificasse o tipo, nao precisariamos ir N vezes na API
        if (bypassLoadProfile)
            return;

        await CorporativoAPI.getPessoas(parseInt(cpf), "Participante", token).then(response => {
            if (response.data === undefined || response.data.Resultado === undefined) {
                console.log("pessoa nao existe no corporativo (participante)");
            }
            else {
                console.log("pessoa existe no corporativo (participante)");
                if (Array.isArray(response.data.Resultado)) {
                    var pessoaCorporativo = Enumerable.From(response.data.Resultado)
                        .Where("$.matricula == '" + matricula + "'").FirstOrDefault();
                    store.commit("savePessoaCorporativo", pessoaCorporativo);
                }
                else
                    store.commit("savePessoaCorporativo", response.data.Resultado);

                isParticipante = true;
            }
        })
            .catch(e => {
                console.log(e);
            });
        if (!isParticipante) {
            await CorporativoAPI.getPessoas(parseInt(cpf), "Representante", token).then(response => {
                if (response.data.Resultado === undefined) {
                    console.log("pessoa nao existe no corporativo (Representante)");
                }
                else {
                    console.log("pessoa existe no corporativo (Representante)");
                    if (Array.isArray(response.data.Resultado)) {
                        var pessoaCorporativo = Enumerable.From(response.data.Resultado)
                            .Where("$.matricula == '" + matricula + "'").FirstOrDefault();
                        store.commit("savePessoaCorporativo", pessoaCorporativo);
                    }
                    else
                        store.commit("savePessoaCorporativo", response.data.Resultado);

                    isDependenteOrRepresentante = true;
                }
            })
                .catch(e => {
                    console.log(e);
                });
        }
        if (!isParticipante && !isDependenteOrRepresentante) {
            await CorporativoAPI.getPessoas(parseInt(cpf), "Dependente", token).then(response => {
                if (response.data.Resultado === undefined) {
                    console.log("pessoa nao existe no corporativo (Dependente)");
                }
                else {
                    console.log("pessoa existe no corporativo (Dependente)");
                    if (Array.isArray(response.data.Resultado)) {
                        var pessoaCorporativo = Enumerable.From(response.data.Resultado)
                            .Where("$.matricula == '" + matricula + "'").FirstOrDefault();
                        store.commit("savePessoaCorporativo", pessoaCorporativo);
                    }
                    else
                        store.commit("savePessoaCorporativo", response.data.Resultado);

                    isDependenteOrRepresentante = true;
                }
            })
                .catch(e => {
                    console.log(e);
                });
        }
    }

    async loadPatrocinado(matricula) {
        var isParticipante = false;
        var isDependenteOrRepresentante = false;

        console.log("recebi a matricula=>" + matricula);

        await PlanoFamiliaAPI.getPatrocinados("participante", matricula, null).then(response => {
            if (response.data === undefined || response.data.resultado === undefined || response.data.resultado == null) {
                console.log("loadPatrocinado - pessoa nao existe no corporativo (participante)");
            }
            else {
                console.log("loadPatrocinado - pessoa existe no corporativo (participante)");
                store.commit("savePatrocinado", response.data.resultado);
                isParticipante = true;
            }
        }).catch(e => { console.log(e); });

        if (!isParticipante) {
            await PlanoFamiliaAPI.getPatrocinados("representante", matricula, null).then(response => {
                if (response.data === undefined || response.data.resultado === undefined || response.data.resultado == null) {
                    console.log("loadPatrocinado - pessoa nao existe no corporativo (Representante)");
                }
                else {
                    console.log("loadPatrocinado - pessoa existe no corporativo (Representante)");
                    store.commit("savePatrocinado", response.data.resultado);
                    isDependenteOrRepresentante = true;
                }
            }).catch(e => { console.log(e); });
        }

        if (!isParticipante && !isDependenteOrRepresentante) {
            await PlanoFamiliaAPI.getPatrocinados("dependente", matricula, null).then(response => {
                if (response.data === undefined || response.data.resultado === undefined || response.data.resultado == null) {
                    console.log("loadPatrocinado - pessoa nao existe no corporativo (Dependente)");
                }
                else {
                    console.log("loadPatrocinado - pessoa existe no corporativo (Dependente)");
                    store.commit("savePatrocinado", response.data.resultado);
                    isDependenteOrRepresentante = true;
                }
            }).catch(e => { console.log(e); });
        }
    }

    notifyError(t, m) {
        Vue.swal({
            icon: 'error',
            title: (t == null || t == "" ? 'Desculpe!' : t),
            text: (m == null || m == "" ? 'Nossos serviços não estão disponíveis no momento.' : m),
        });
    }

    async putPessoa(id, pessoa) {
        //parse _ tags
        var _rid = pessoa._rid;
        var _self = pessoa._self;
        var _etag = pessoa._etag;
        var _attachments = pessoa._attachments;
        var _ts = pessoa._ts;

        delete pessoa._rid;
        delete pessoa._self;
        delete pessoa._etag;
        delete pessoa._attachments;
        delete pessoa._ts;

        pessoa._rid = _rid;
        pessoa._self = _self;
        pessoa._etag = _etag;
        pessoa._attachments = _attachments;
        pessoa._ts = _ts;
        pessoa = this.formatarDadosPessoa(pessoa);
        try {
            const response = await PlanoFamiliaAPI.putPessoas(id, pessoa);
            pessoa = response.data;
            return { success: true}; // Retornar sucesso e a pessoa atualizada
        } catch (e) {
            return { success: false, error: e.message }; // Retornar o erro ocorrido
        }
    }

    removeAcentosECaracteresEspeciais(str) {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9\s]/g, "").trim();
    }
    
    formatarDadosPessoa(pessoa) {
        if (pessoa.nome) {
            pessoa.nome = this.removeAcentosECaracteresEspeciais(pessoa.nome);
        }
    
        if (pessoa['participante-valia'] && pessoa['participante-valia'].nome) {
            pessoa['participante-valia'].nome = this.removeAcentosECaracteresEspeciais(pessoa['participante-valia'].nome);
        }

        if (pessoa.beneficiarios && Array.isArray(pessoa.beneficiarios)) {
            pessoa.beneficiarios.forEach((beneficiario) => {
                if (beneficiario.nome) {
                    beneficiario.nome = this.removeAcentosECaracteresEspeciais(beneficiario.nome);
                }
            });
        }
    
        if (pessoa['perfil-pessoal'] && pessoa['perfil-pessoal'].identificacao) {   
            if (pessoa['perfil-pessoal'].identificacao['orgao-emissor']) {
                pessoa['perfil-pessoal'].identificacao['orgao-emissor'] = this.removeAcentosECaracteresEspeciais(pessoa['perfil-pessoal'].identificacao['orgao-emissor']);
            }

            if (pessoa['perfil-pessoal'].identificacao.numero) {
                pessoa['perfil-pessoal'].identificacao.numero = this.removeAcentosECaracteresEspeciais(pessoa['perfil-pessoal'].identificacao.numero);
            }

            if (pessoa['perfil-pessoal'].identificacao['nome-pai']) {
                pessoa['perfil-pessoal'].identificacao['nome-pai'] = this.removeAcentosECaracteresEspeciais(pessoa['perfil-pessoal'].identificacao['nome-pai']);
            }
    
            if (pessoa['perfil-pessoal'].identificacao['nome-mae']) {
                pessoa['perfil-pessoal'].identificacao['nome-mae'] = this.removeAcentosECaracteresEspeciais(pessoa['perfil-pessoal'].identificacao['nome-mae']);
            }
        }
    
        if (pessoa['perfil-pessoal'] && pessoa['perfil-pessoal'].endereco) {
            if (pessoa['perfil-pessoal'].endereco.CEP) {
                pessoa['perfil-pessoal'].endereco.CEP = this.removeAcentosECaracteresEspeciais(pessoa['perfil-pessoal'].endereco.CEP);
            }
    
            if (pessoa['perfil-pessoal'].endereco.endereco) {
                pessoa['perfil-pessoal'].endereco.endereco = this.removeAcentosECaracteresEspeciais(pessoa['perfil-pessoal'].endereco.endereco);
            }
    
            if (pessoa['perfil-pessoal'].endereco.bairro) {
                pessoa['perfil-pessoal'].endereco.bairro = this.removeAcentosECaracteresEspeciais(pessoa['perfil-pessoal'].endereco.bairro);
            }
    
            if (pessoa['perfil-pessoal'].endereco.cidade) {
                pessoa['perfil-pessoal'].endereco.cidade = this.removeAcentosECaracteresEspeciais(pessoa['perfil-pessoal'].endereco.cidade);
            }
    
            if (pessoa['perfil-pessoal'].endereco.uf) {
                pessoa['perfil-pessoal'].endereco.uf = this.removeAcentosECaracteresEspeciais(pessoa['perfil-pessoal'].endereco.uf);
            }
    
            if (pessoa['perfil-pessoal'].endereco.numero) {
                pessoa['perfil-pessoal'].endereco.numero = this.removeAcentosECaracteresEspeciais(pessoa['perfil-pessoal'].endereco.numero);
            }
    
            if (pessoa['perfil-pessoal'].endereco.complemento) {
                pessoa['perfil-pessoal'].endereco.complemento = this.removeAcentosECaracteresEspeciais(pessoa['perfil-pessoal'].endereco.complemento);
            }
        }
    
        if (pessoa.representante && pessoa.representante.nome) {
            pessoa.representante.nome = this.removeAcentosECaracteresEspeciais(pessoa.representante.nome);
        }

        if (pessoa['perfil-pessoal'] && pessoa['perfil-pessoal'].naturalidade) {
            pessoa['perfil-pessoal'].naturalidade = this.removeAcentosECaracteresEspeciais(pessoa['perfil-pessoal'].naturalidade);
        }

        if (pessoa['perfil-investidor'] && pessoa['perfil-investidor'].textoAtendimento) {
            pessoa['perfil-investidor'].textoAtendimento = this.removeAcentosECaracteresEspeciais(pessoa['perfil-investidor'].textoAtendimento);
        }
    
        return pessoa;
    }

    calculate_age(dob) {
        var diff_ms = Date.now() - dob.getTime();
        var age_dt = new Date(diff_ms);

        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }

    // decodeCRUrl(cr) {

    //     var keyc = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_param_in_key);
    //     var ivc = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_param_in_iv);

    //     ////////////
    //     var cpfProtocoloUrlDecoded = decodeURIComponent(cr);
    //     var cpfProtocoloC = CryptoJS.enc.Base64.parse(cpfProtocoloUrlDecoded);
    //     var eparam = CryptoJS.lib.CipherParams.create({
    //         ciphertext: cpfProtocoloC,
    //         formatter: CryptoJS.format.OpenSSL
    //     });
    //     var decrypted = CryptoJS.AES.decrypt(eparam, keyc, { iv: ivc });
    //     var dparam = decrypted.toString(CryptoJS.enc.Utf8);

    //     var nCpf = dparam.split(";")[0];
    //     var nProtocolo = dparam.split(";")[1];

    //     return {
    //         "cpf": nCpf, "protocolo": nProtocolo
    //     };
    //     ////////////

    // }

    decodeCRUrl(cr) {
        // Verifica se VUE_APP_ignore_crypto está definido como TRUE para ignorar a criptografia
        if (process.env.VUE_APP_ignore_crypto && process.env.VUE_APP_ignore_crypto === 'true') {
            // Assume que a string está no formato "cpf;protocolo" e retorna diretamente
            var parts = decodeURIComponent(cr).split(";");
            return {
                "cpf": parts[0],
                "protocolo": parts[1]
            }; 
        }
    
        // Caso contrário, procede com a desencriptação e decodificação
        var keyc = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_param_in_key);
        var ivc = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_param_in_iv);
    
        var cpfProtocoloUrlDecoded = decodeURIComponent(cr);
        var cpfProtocoloC = CryptoJS.enc.Base64.parse(cpfProtocoloUrlDecoded);
        var eparam = CryptoJS.lib.CipherParams.create({
            ciphertext: cpfProtocoloC,
            formatter: CryptoJS.format.OpenSSL
        });
        var decrypted = CryptoJS.AES.decrypt(eparam, keyc, { iv: ivc });
        var dparam = decrypted.toString(CryptoJS.enc.Utf8);
    
        var nCpf = dparam.split(";")[0];
        var nProtocolo = dparam.split(";")[1];
    
        return {
            "cpf": nCpf,
            "protocolo": nProtocolo
        };
    }

    keepCloning(objectpassed) {
        if (objectpassed === null || typeof objectpassed !== 'object') {
            return objectpassed;
        }// give temporary-storage the original obj's constructor
        var temporarystorage = objectpassed.constructor();
        for (var key in objectpassed) {
            temporarystorage[key] = this.keepCloning(objectpassed[key]);
        }
        return temporarystorage;
    }

    createEtapa(titulo, nome, cpf, ip) {

        var etapa = {};

        etapa.descricao = titulo;
        etapa.usuario = {};
        etapa.usuario.nome = nome;
        etapa.usuario.cpf = cpf;
        etapa.dataConclusao = moment(new Date(), "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY HH:mm:ss");
        etapa.enderecoIp = ip;

        return etapa;
    }
}

export default new Common();