<template>
    <transition name="slide-up">
        <div class="content-container content-half" v-if="isActive">
            <v-form ref="form">
                <div class="padding-div">
                    <div class="content-title">
                        <div class="fs-32px fw-700 color-dark mb-5">Revise seus dados:</div>
                    </div>
                    <div class="content-contents">
                        <div class="mb-4 mt-5">
                            <div class="fs-24px fw-500 color-dark">Valor da contribuição mensal</div>
                            <div class="d-flex ">
                                <div class="n-form">
                                    <div class="single-input">
                                        <v-text-field-money v-model="valorContribuicao" required :rules="[rules.required, rules.contribuicao]" placeholder="Digite sua resposta aqui" @keyup.native.enter="next" v-on:keyup.enter="next"
                                                            v-bind:properties="{
        prefix: 'R$',
        readonly: false,
        disabled: false,
        outlined: false,
        clearable: true,
        placeholder: ' ',
      }"
                                                            v-bind:options="{
        locale: 'pt-BR',
        length: 11,
        precision: 2,
        empty: null,
      }" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mb-4">
                        <div class="fs-24px fw-500 color-dark">Politicamente exposto?</div>
                        <div class="d-flex ">
                            <div class="n-form">
                                <div class="single-input">
                                    <v-select v-bind:items="itemsPPE" v-model="valPPE" item-value="value" item-text="text"></v-select>
                                </div>
                                <!--required :rules="[rules.required]" -->
                            </div>
                        </div>
                    </div>

                    <div class="mb-4">
                        <div class="fs-24px fw-500 color-dark">Forma de pagamento da contribuição</div>
                        <div class="d-flex">
                            <div class="n-form">
                                <div class="single-input">
                                    <v-select :disabled="formaPagamentoBoleto" v-bind:items="itemsFormaPagamento" required :rules="[rules.required]" v-model="valFormaPagamento" item-value="value" item-text="text"></v-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="mb-4" v-show="false">
                        <div class="fs-24px fw-500 color-dark">Perfil de Investimento</div>
                        <div class="d-flex">
                            <div class="n-form">
                                <div class="single-input">
                                    <v-select v-bind:items="itemsPerfilInvestimento" required :rules="[rules.required]" v-model="valPerfilInvestimento" item-value="value" item-text="text"></v-select>
                                </div>
                                <div class="valia-tooltip-no-text-decoration">
                                    <v-icon style="color:#0972D2;display:block;" v-if="showBaseTip">information</v-icon>
                                    <span class="valia-tooltiptext">O questionário do perfil de investidor não foi respondido, ou, não foi respondido por completo, por esse motivo você foi alocado no perfil BASE. Mas, você pode selecionar outro agora.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-buttons">
                    <div class="d-flex justify-content-end">
                        <button v-on:click="next" class="button-primary-bg-primary action-button">
                            Concluir Adesão
                            <v-icon right>east</v-icon>
                        </button>
                    </div>
                    <div class="d-flex justify-content-end">
                        <div class="fs-13px mt-3 ml-3" v-show="!aceitouRegras">Declaro que li, compreendi e aceitei as regras, termos e condições do <a href="https://adesao.valia.com.br/Content/docs/Regulamento_Perfil_PREVALER_FINAL.PDF" target="_blank">Regulamento</a> para o exercício da opção e alteração da opção de investimento por meio eletrônico.</div>
                    </div>
                </div>
            </v-form>
        </div>
    </transition>
</template>
<script>
    import 'bootstrap';
    import CorporativoAPI from '../../services/CorporativoAPI';
    import { mapState } from "vuex";
    import Decimal from "../../components/Decimal.vue";
    import NotificationService from '../../services/notifications/NotificationService'; 
    export default {
        name: 's05-revise',
        props: ['pessoa', 'visible', 'descontofolhaativo', 'perfilInvestidor', 'fluxo'],
        components: {
            "v-text-field-money": Decimal,
        },
        data: () => ({
            isActive: false,
            loading: false,
            selection: false,
            step: 1,
            hasErrors: false,
            descontoFolha: null,
            showBaseTip: false,
            valorContribuicao: "0.00",
            itemsPPE: [{ text: 'Sim', value: true }, { text: 'Não', value: false }],
            valPPE: true,
            itemsFormaPagamento: [],
            valFormaPagamento: "",
            itemsPerfilInvestimento: [
                { text: 'Prevaler Base', value: "34" },
                { text: 'Prevaler Moderado', value: "32" },
                { text: 'Prevaler Multi', value: "31" },
                { text: 'Prevaler Ative', value: "33" },
                { text: 'Prevaler Intense', value: "55" }],
            //
            valPerfilInvestimento: "",
            showFolhaDePagamento: false,
            aceitouRegras: false,
            rules: {
            },
            formaPagamentoBoleto: false,
            formapagamentoboletoSent: false,
            formapagamentofolhaSent: false,
        }),
        watch: {
            visible: function () {
                this.toggle();
            }
        },
        computed: {
            ...mapState(["questionario"]),
            ...mapState(["patrocinado"]),
        },
        methods: {
            toggle() {
                this.isActive = !this.isActive;
                if (this.isActive) {
                    this.habilitaOpcoes();
                    //nao preencheu questionario
                    if (this.questionario.questao01 != 0)
                        this.aceitouRegras = true;
                }
            },
            habilitaOpcoes() {
                // var vm = this;
                if (this.pessoa !== null && this.pessoa["perfil-plano"] != null && this.pessoa["perfil-plano"]["valor-contribuicao"] != null) {
                    var v = this.pessoa["perfil-plano"]["valor-contribuicao"]
                    var nv = v.replace(',', '.');
                    this.valorContribuicao = nv;
                }
                this.valPPE = (this.pessoa["perfil-pessoal"].ppe == "S" ? true : false);
                this.valPerfilInvestimento = this.pessoa["perfil-investidor"].perfilSelecionado;
                this.valFormaPagamento = this.pessoa["perfil-plano"]["forma-cobranca"];
                var formaCobrancaInicial = this.pessoa["perfil-plano"]["forma-cobranca"];

                if (this.valPerfilInvestimento == null || this.valPerfilInvestimento == "")
                    this.valPerfilInvestimento = this.pessoa["perfil-investidor"].perfilSugerido;



                    if (formaCobrancaInicial.toLowerCase() == "b" && 
                    (this.fluxo == "convidadomenor18" || this.fluxo == 'convidadomaior18' || this.fluxo == 'maior18' || (this.fluxo == "aberturamaior18" && this.pessoa["perfil-pessoal"].codigoEmpresa == null))) {
                        this.formaPagamentoBoleto = true;
                    }  
                    else if (formaCobrancaInicial.toLowerCase() == "f" || (this.fluxo == "aberturamaior18" && this.pessoa["perfil-pessoal"].codigoEmpresa != null)) {
                        this.showFolhaDePagamento = true;
                        this.finished = true;
                    } else {
                        var tipoPublico = this.pessoa["participante-valia"]["tipo-publico"];
                        if (tipoPublico != null) {
                            var ativo = (tipoPublico == "ATIVOS DESCONTO EM FOLHA");
                            //var aposentado = (tipoPublico == "aposentado");
                            var aposentado = (tipoPublico == "aposentado" || tipoPublico == "APOSENTADO" || tipoPublico == "aposentado programado" || tipoPublico == "APOSENTADO PROGRAMADO");
                            var pensionista = (tipoPublico == "pensionista");
                        }
                        if (ativo) {
                            //EventBus.$emit("disableInterceptor");
                            //
                            CorporativoAPI.getEmpresasDescontoFolha(this.pessoa["participante-valia"].empresa)
                                .then(response => {
                                    this.descontoFolha = response.data;
                                    this.showFolhaDePagamento = this.descontoFolha.habilitada && this.descontoFolha.encontrada;
                                    // EventBus.$emit("enableInterceptor");
                                })
                                .catch(e => {
                                    console.log(e);
                                    // EventBus.$emit("enableInterceptor");
                                });
                        }
                        else if (aposentado || pensionista) {
                            this.showFolhaDePagamento = true;
                        }                       
                    }
                this.preencheFormasPagamento();
                if (this.perfilInvestidor !== undefined && (this.perfilInvestidor.questao01 === undefined && this.perfilInvestidor.questao02 === undefined && this.perfilInvestidor.questao03 === undefined && this.perfilInvestidor.questao04 === undefined && this.perfilInvestidor.questao05 === undefined)) {
                    this.showBaseTip = true;
                }
                else {
                    this.showBaseTip = false;
                }


            },
            preencheFormasPagamento: function () {
                this.itemsFormaPagamento = [];
                if (this.showFolhaDePagamento) {
                    this.itemsFormaPagamento.push({ text: 'Desconto em folha', value: 'f' });
                    this.itemsFormaPagamento.push({ text: 'Boleto', value: 'b' });
                }
                else {
                    this.itemsFormaPagamento.push({ text: 'Boleto', value: 'b' });
                    this.valFormaPagamento = this.itemsFormaPagamento[0].value;
                }
            },
            next: function (event) {
                event.preventDefault();


                this.rules = {
                    required: value => !!value || 'Campo obrigatório.',
                    contribuicao: value => {
                        if (value !== undefined && value !== null && value != "") {
                            if (parseFloat(value.replace('.', '').replace(',', '.')) < 50) {
                                return "Contribuição inválida";
                            }
                        }
                        else {
                            return "Contribuição inválida";
                        }
                    },
                };

                this.$nextTick(() => {
                    if (this.$refs.form.validate()) {
                        var v = this.valorContribuicao;
                        var nv = v.replace('.', ',');
                        this.pessoa["perfil-plano"]["valor-contribuicao"] = nv;

                        //TODO: Caso nao tenha nada selecionado e o usuario veio do NAO quero responder nao setar o perfilSelecionado
                        this.pessoa["perfil-investidor"].perfilSelecionado = this.valPerfilInvestimento;
                        this.pessoa["perfil-pessoal"].ppe = (this.valPPE == true ? "S" : "N");
                        this.pessoa["perfil-plano"]["forma-cobranca"] = this.valFormaPagamento;
                        if(this.fluxo != "aberturamaior18" && this.fluxo != "aberturamenor18" && this.fluxo != "abertura"){
                            var emailParticipanteValia = this.patrocinado.EMAIL_PRINCIPAL;
                            if (this.pessoa["perfil-plano"]["forma-cobranca"] == "b") {
                                if (!this.formapagamentoboletoSent) {
                                    NotificationService.formapagamentoboleto(this.fluxo, this.pessoa, this.userIP, emailParticipanteValia);
                                    this.formapagamentoboletoSent = true;
                                }
                            }
                            else {
                                if (!this.formapagamentofolhaSent) {
                                    NotificationService.formapagamentofolha(this.fluxo, this.pessoa, this.userIP, emailParticipanteValia);
                                    this.formapagamentofolhaSent = true;
                                }
                            }
                        }
                        if (this.fluxo == "proprio" || this.fluxo == "propriodpub" || this.fluxo == "maior18" || this.fluxo == "convidadomaior18") {
                            this.pessoa.representante = {
                                "nome": null,
                                "cpf": null,
                                "data-nascimento": null,
                                "tipo-representacao": "0",
                                "email": null,
                                "telefone": {
                                    "ddi": "55",
                                    "ddd": null,
                                    "numero": null,
                                    "tipo": "0"
                                }
                            };
                        }

                        this.hasErrors = false;
                        this.$emit('next');
                    }
                })

            },
            back: function () {
                this.$emit('back');
            }
        },
        created() {
        }
    };
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

